import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "authorising-a-payment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#authorising-a-payment",
        "aria-label": "authorising a payment permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Authorising a payment`}</h2>
    <p>{`Using the `}<strong parentName="p"><inlineCode parentName="strong">{`x-token`}</inlineCode></strong>{` from the previous step, you are now able to render the payment authorization frame on your web app.`}</p>
    <p>{`The payment authorization frame will capture required details from your patient in order to authorize a payment in Medipass.`}</p>
    <h4 {...{
      "id": "step-1-prepare-your-html",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#step-1-prepare-your-html",
        "aria-label": "step 1 prepare your html permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 1. Prepare your HTML`}</h4>
    <p>{`So that the Payment Authority SDK can inject it's frame into your webpage, insert a `}<strong parentName="p"><inlineCode parentName="strong">{`div`}</inlineCode></strong>{` element with a unique `}<strong parentName="p"><inlineCode parentName="strong">{`id`}</inlineCode></strong>{` attribute into your webpage.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<div id="authorize-payment"></div>
`}</code></pre>
    <h4 {...{
      "id": "step-2-render-the-payment-authorization-frame",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#step-2-render-the-payment-authorization-frame",
        "aria-label": "step 2 render the payment authorization frame permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2. Render the payment authorization frame`}</h4>
    <p>{`Then, you can invoke the `}<strong parentName="p"><inlineCode parentName="strong">{`sdk.renderAuthorizePayment`}</inlineCode></strong>{` function from in your JavaScript module:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import sdk from '@medipass/partner-sdk';

sdk.setConfig({
  appId: 'your-app-id',
  appVersion: '1.0.0',
  env: 'stg'
});

sdk.renderAuthorizePayment(
  // Attributes
  {
    funders: ['medicare']
  },

  // Configuration
  {
    token: 'your-x-token',
    containerId: 'authorize-payment',
    width: '400px',

    onSuccess: data => {
      console.log('Success!', JSON.stringify(data));
      setStatus('success');
    },
    onError: err => {
      console.log('Error!', JSON.stringify(err));
      setStatus('errored');
    }
  }
);
`}</code></pre>
    <h4 {...{
      "id": "step-2a-set-additional-sdk-attributes--config",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#step-2a-set-additional-sdk-attributes--config",
        "aria-label": "step 2a set additional sdk attributes  config permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2a. Set additional SDK attributes & config`}</h4>
    <p>{`You can also set up some additional attributes & configuration which covers the following:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/sdk/payment-authority/capturing-funder/"
        }}>{`Capturing funder details`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/sdk/payment-authority/prepopulating/"
        }}>{`Prepopulating patient details`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/sdk/payment-authority/submit-button/"
        }}>{`Setting up your own submit button`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      